import React from 'react';
import './App.css';
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import Form from "./components/Form";

const Page = styled.div``

const FeaturedSectionTitle = styled.div`
  font-size: 2rem;
  color: red;
  font-weight: 900;
`
const FeaturedSectionSubtitle = styled.div`
  font-size: 1.5rem;
  font-weight: 900;
  width: 85%;
`
const FeaturedSectionInfo = styled.div` 
    width: 80%;
  padding-right: 20px;
`

const FeaturedSectionImage = styled.div`
    width: 500px;
`

const ByCaption = styled.div`
  font-weight: 500;
  position: relative;
  bottom: 70px;
  left: 0;
  letter-spacing: 5px;
  
`

const Headline = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 0;
  font-size: 1.5rem;
  font-family: Poppins, Inter, sans-serif;

  @media (min-width: 1200px) {
    font-size: 6rem;
    margin-bottom: -10px;
    letter-spacing: -4px;
  }
`

const SubHeading = styled.p`
  font-family: "Libre Baskerville";
  max-width: 1050px;
  margin: 0 auto 25px auto;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`

const Moves = styled.span`
  color: #EB3C3C;
`
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`
const FormContainer = styled.div`
    display: flex;
    justify-content: center;
`
const FeaturedSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin: 20px auto;
    background-color: #f9f9f9;
    padding: 30px;
    border-radius: 10px;
`

const Hero = styled.div`
  position: relative;
`
const Caption = styled.p`
  font-family: "Libre Baskerville";
  font-style: italic;
  font-size: 16px;
  margin-bottom: 25px;
`
const QuickReadButton = styled.button`
  border: 0;
  text-transform: uppercase;
  background-color: black;
  font-weight: bold;
  padding: 12px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
`
const QuickReadButtonLocked = styled.button`
  border: 0;
  text-transform: uppercase;
  background-color: grey;
  font-weight: bold;
  padding: 12px;
  margin-top: 20px;
  border-radius: 5px;
  color: white;
`
const HeadlineContainer = styled.div`
  display: flex;
  justify-content: center;
`


function App() {
    const route = useNavigate()
  return (
      <Page>
        <Hero className="App">
            <Container>
                <HeadlineContainer><Headline>Shrewdest</Headline><Headline><Moves>Moves</Moves></Headline></HeadlineContainer>
                <SubHeading>“Stories of Mighty Kings, Iconic Brands and Visionaries Minds Who Out-manoeuvred Their Opponents And Redefined History.”</SubHeading>
                <FormContainer>
                    <Form />
                </FormContainer>
                {/*<Caption>Join 450,000+ strategic thinkers who read fresh off the press every Sunday</Caption>*/}
                <Caption>Weekly newsletter discerning the axioms of power for strategic thinkers.</Caption>
            </Container>
        </Hero>

          {/*<FeaturedSection>*/}
        {/*    <FeaturedSectionInfo>*/}
        {/*        <FeaturedSectionTitle>BERNAYS PROPAGANDA:</FeaturedSectionTitle>*/}
        {/*        <FeaturedSectionSubtitle>HOW TO START A MOVEMENT AND MANIPULATE THE MASSES</FeaturedSectionSubtitle>*/}
        {/*        <article>*/}
        {/*            <br/>*/}
        {/*            What, if I told you everything you wish you had, and desired, every decision you make is the result of social engineering*/}
        {/*            manipulating your subconscious mind by inciting powerful emotions.<br/><br/>*/}

        {/*            Emotions of fear, greed, and happiness. <br/><br/>*/}

        {/*            Would you believe me?*/}

        {/*        </article>*/}
        {/*        <QuickReadButton onClick={() => route('/post')} >Sneak Peak</QuickReadButton>*/}
        {/*    </FeaturedSectionInfo>*/}
        {/*    <FeaturedSectionImage>*/}
        {/*        <img src='/kingpin.png' width={'70%'}/>*/}
        {/*    </FeaturedSectionImage>*/}
        {/*</FeaturedSection>*/}
        {/*  <FeaturedSection>*/}
        {/*      <FeaturedSectionInfo>*/}
        {/*          <FeaturedSectionTitle>EMPRESS WU ZETIAN:</FeaturedSectionTitle>*/}
        {/*          <FeaturedSectionSubtitle>DESPICABLE LAWS OF POWER BY CHINA’S ONLY FEMALE RULER IN 3000 YEARS</FeaturedSectionSubtitle>*/}
        {/*          <article>*/}
        {/*              <br/>*/}
        {/*              What, if I told you everything you wish you had, and desired, every decision you make is the result of social engineering*/}
        {/*              manipulating your subconscious mind by inciting powerful emotions.<br/><br/>*/}

        {/*              Emotions of fear, greed, and happiness. <br/><br/>*/}

        {/*              Would you believe me?*/}

        {/*          </article>*/}
        {/*          <QuickReadButtonLocked>Locked</QuickReadButtonLocked>*/}
        {/*      </FeaturedSectionInfo>*/}
        {/*      <FeaturedSectionImage>*/}
        {/*          <img src='/f2.png' width={'70%'}/>*/}
        {/*      </FeaturedSectionImage>*/}
        {/*  </FeaturedSection>*/}
        {/*  <FeaturedSection>*/}
        {/*      <FeaturedSectionInfo>*/}
        {/*          <FeaturedSectionTitle>DE BEERS KILLED THE MARKET:</FeaturedSectionTitle>*/}
        {/*          <FeaturedSectionSubtitle>HOW TO START A DIAMOND MONOPOLY</FeaturedSectionSubtitle>*/}
        {/*          <article>*/}
        {/*              <br/>*/}
        {/*              What, if I told you everything you wish you had, and desired, every decision you make is the result of social engineering*/}
        {/*              manipulating your subconscious mind by inciting powerful emotions.<br/><br/>*/}

        {/*              Emotions of fear, greed, and happiness. <br/><br/>*/}

        {/*              Would you believe me?*/}

        {/*          </article>*/}
        {/*          <QuickReadButtonLocked>Locked</QuickReadButtonLocked>*/}
        {/*      </FeaturedSectionInfo>*/}
        {/*      <FeaturedSectionImage>*/}
        {/*          <img src='/f3.png' width={'70%'}/>*/}
        {/*      </FeaturedSectionImage>*/}
        {/*  </FeaturedSection>*/}
      </Page>
  );
}

export default App;
