import styled from "styled-components";

const EmailField = styled.input`
  background: lightgrey;
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px;
  margin: 5px;

  @media (min-width: 768px) {
    width: 450px;
  }
`
const SubmitButton = styled.button`
  border: 1px black solid;;
  text-transform: uppercase;
  color: black;
  background-color: #FFF500;
  font-weight: bold;
  padding: 15px;
  border-radius: 5px;
  margin:5px;
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  @media (min-width: 768px) {
    width: fit-content;
    flex-direction: row;
  }
`

export default function Form() {


    return (
        <FormStyled
            action="https://app.convertkit.com/forms/6341567/subscriptions"
            className="seva-form formkit-form"
            method="post" data-sv-form="6341567"
            data-uid="c6f929f0b4"
            data-format="inline"
            data-version="5"
            data-options="{&quot;settings&quot;:{&quot;after_subscribe&quot;:{&quot;action&quot;:&quot;message&quot;,&quot;success_message&quot;:&quot;Success! Now check your email to confirm your subscription.&quot;,&quot;redirect_url&quot;:&quot;&quot;},&quot;analytics&quot;:{&quot;google&quot;:null,&quot;fathom&quot;:null,&quot;facebook&quot;:null,&quot;segment&quot;:null,&quot;pinterest&quot;:null,&quot;sparkloop&quot;:null,&quot;googletagmanager&quot;:null},&quot;modal&quot;:{&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;powered_by&quot;:{&quot;show&quot;:true,&quot;url&quot;:&quot;https://convertkit.com/features/forms?utm_campaign=poweredby&amp;utm_content=form&amp;utm_medium=referral&amp;utm_source=dynamic&quot;},&quot;recaptcha&quot;:{&quot;enabled&quot;:false},&quot;return_visitor&quot;:{&quot;action&quot;:&quot;show&quot;,&quot;custom_content&quot;:&quot;&quot;},&quot;slide_in&quot;:{&quot;display_in&quot;:&quot;bottom_right&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15},&quot;sticky_bar&quot;:{&quot;display_in&quot;:&quot;top&quot;,&quot;trigger&quot;:&quot;timer&quot;,&quot;scroll_percentage&quot;:null,&quot;timer&quot;:5,&quot;devices&quot;:&quot;all&quot;,&quot;show_once_every&quot;:15}},&quot;version&quot;:&quot;5&quot;}"
            min-width="400 500 600 700 800"
            data-gtm-form-interact-id="0"
        >
            <EmailField
                name="email_address"
                aria-label="Email Address"
                placeholder="Email Address"
                required
                type="email"
                data-gtm-form-interact-field-id="0"
            />
            <SubmitButton> Subscribe </SubmitButton>
        </FormStyled>
    )
}