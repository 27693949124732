import React from 'react';
import styled from "styled-components";

const Headline = styled.h1`
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 0px;
  text-align: center;
  font-size: 80px;
  font-style: italic;
  font-family: "Poppins", Inter,sans-serif;
  letter-spacing: -4px;

`

const SubHeading = styled.p`
  font-family: "Libre Baskerville";
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  margin-top: 10px;
`

const Moves = styled.span`
  color: #EB3C3C;
`

const Container = styled.div`
    width: 80%;
    margin: auto;
`

const Main = styled.div`
  position: relative;
  top: 100px;
`
const Caption = styled.p`
  font-family: "Libre Baskerville";
  font-style: italic;
  font-size: 12px;
  margin-bottom: 25px;
`

const EmailField = styled.input`
  width: 350px;
  background: lightgrey;
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
`
const SubmitButton = styled.button`
  border: 0;
  text-transform: uppercase;
  background-color: #FFF500;
  font-weight: bold;
  padding: 12px;
  margin-left: 5px;
  border-radius: 5px;
`
const Article = styled.article`
  width: 75%;
  margin: auto;
  font-size: 24px;
  font-family: "Libre Baskerville";

`
const Image = styled.img`
  text-align: center;
  display: block;
  margin: 50px auto;
`

function Post() {
    return (
        <Main>
            <Container>
                <Headline>How to build a <br />diamond monopoly</Headline>
                <SubHeading>
                    Debeers and Co. made a common gem like Diamond and <br/> turned it into a girls best friend using psychology
                </SubHeading>
                <Article>
                    In the 1870, Cecil Rhodes arrived in South Africa from England during the diamond rush in the city of Kimerberly. <br/><br/>

                    <Image src={'img1.png'} width={'auto'}/>

                    Rhodes quickly realised the Kimberley mines had a significant problem: water. <br/><br/>

                    As the diamond mines grew deeper, they frequently filled with water, making mining operations increasingly difficult. <br/><br/>

                    So he began selling water pumps designed to de-water the mines allowing for deeper and more efficient diamond extraction. <br/><br/>

                    Water pumps are not intrinsically valuable, but to those minors in the diamond fields, water pumps were of high neccestiy. People purchase based on perceived value, not intrinsic value.
                </Article>
            </Container>
        </Main>
    );
}

export default Post;
